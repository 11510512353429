import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FinanceiroService } from 'src/app/_services/Financeiro/financeiro.service';
import { MinhacontaService } from 'src/app/_services/MinhaConta/minhaconta.service';

@Component({
  selector: 'app-person-profile',
  templateUrl: './person-profile.component.html',
  styleUrls: ['./person-profile.component.scss']
})
export class PersonProfileComponent implements OnInit {

  public maskCpfCnpj: string = '';
  public tamanhoImagem = 0;
  public imagemDescricaoTamaho: any;
  stepOneForm: FormGroup;
  stepWebHook: FormGroup;
  public bancoList: Array<any> = [];
  public parametros:any;
  public bancoContaList: Array<any> = [];
  public idContaToDelete: any;
  public parametrosList: Array<any> = [];
  public base64textString: any;

  public usuario: any;

  public cobranca:any;

  public parametrosWebHookEnviar: Array<any> = [];

  public totalBancos: number = 0;

  //************VARIAVÉIS DE PAGINAÇÃO ********************
  public numberItemsPageBancos: number = 10;
  public currentPageBancos: number = 0;
  public totalPagesBancos: number = 0;
  //*******************************************************

  public dataParametro = {
    "chave": "",
    "valor": ""
  };

  public numberButtonsPaginationBancos: Array<number> = [];

  public pessoaForm = new FormGroup({
    descricao: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    cpfCnpj: new FormControl('', [Validators.required]),
    nomeFantasia: new FormControl('', [Validators.required]),
    celular: new FormControl('', [Validators.required]),
    cep: new FormControl('', [Validators.minLength(8)]),
    cidade: new FormControl('', [Validators.required]),
    logradouro: new FormControl('', [Validators.required]),
    bairro: new FormControl('', [Validators.required]),
    numero: new FormControl('', [Validators.required]),
    complemento: new FormControl(''),
  })

  constructor(
    private formBuilder: FormBuilder,
    private financeiroService: FinanceiroService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    private httpClient: HttpClient,
    private minhacContaService: MinhacontaService,
    private spinner: NgxSpinnerService,
  ) { }


  ngOnInit(): void {
   
    this.stepOneForm = this.formBuilder.group({
      banco: ['', Validators.required],
      agencia: ['', Validators.required],
      nr_conta: ['', Validators.required]
    });

    this.stepWebHook = this.formBuilder.group({
      ativar: ['', Validators.required],
      url: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      token: ['', Validators.required],
      status_fila: ['', Validators.required]
    });

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    this.minhacContaService.listarDadosMinhaConta().subscribe((result) => {
     this.pessoaForm.controls['descricao'].setValue(result['minhaConta']['descricao']);
     this.pessoaForm.controls['email'].setValue(result['minhaConta']['email']);
     this.pessoaForm.controls['cpfCnpj'].setValue(result['minhaConta']['cpfCnpj']);
     this.pessoaForm.controls['nomeFantasia'].setValue(result['minhaConta']['nomeFantasia']);
     this.pessoaForm.controls['celular'].setValue(result['minhaConta']['celular']);
     this.pessoaForm.controls['cep'].setValue(result['minhaConta']['cep']);
     this.pessoaForm.controls['cidade'].setValue(result['minhaConta']['cidade']);
     this.pessoaForm.controls['logradouro'].setValue(result['minhaConta']['logradouro']);
     this.pessoaForm.controls['bairro'].setValue(result['minhaConta']['bairro']);
     this.pessoaForm.controls['numero'].setValue(result['minhaConta']['numero']);
     this.pessoaForm.controls['complemento'].setValue(result['minhaConta']['complemento']);
 
     if (result['minhaConta']['fisicaJuridica'] == 'F') {
       this.maskCpfCnpj = '000.000.000-00';
     }
     else if (result['minhaConta']['fisicaJuridica'] == 'J') {
       this.maskCpfCnpj = '00.000.000/0000-00';
     }
    }, (err) => {
      this.toastr.error(err.error.message, 'Erro', {
        timeOut: 5000,
      })
    });    

    this.pessoaForm.controls['descricao'].setValue(currentUser['minhaConta']['descricao']);
    this.pessoaForm.controls['email'].setValue(currentUser['minhaConta']['email']);
    this.pessoaForm.controls['cpfCnpj'].setValue(currentUser['minhaConta']['cpfCnpj']);
    this.pessoaForm.controls['nomeFantasia'].setValue(currentUser['minhaConta']['nomeFantasia']);
    this.pessoaForm.controls['celular'].setValue(currentUser['minhaConta']['celular']);
    this.pessoaForm.controls['cep'].setValue(currentUser['minhaConta']['cep']);
    this.pessoaForm.controls['cidade'].setValue(currentUser['minhaConta']['cidade']);
    this.pessoaForm.controls['logradouro'].setValue(currentUser['minhaConta']['logradouro']);
    this.pessoaForm.controls['bairro'].setValue(currentUser['minhaConta']['bairro']);
    this.pessoaForm.controls['numero'].setValue(currentUser['minhaConta']['numero']);
    this.pessoaForm.controls['complemento'].setValue(currentUser['minhaConta']['complemento']);

    if (currentUser['minhaConta']['fisicaJuridica'] == 'F') {
      this.maskCpfCnpj = '000.000.000-00';
    }
    else if (currentUser['minhaConta']['fisicaJuridica'] == 'J') {
      this.maskCpfCnpj = '00.000.000/0000-00';
    }

    this.usuario = currentUser;

    if(this.usuario.minhaConta.foto != null){    

      this.minhacContaService.getLogo(this.usuario.minhaConta.id).subscribe((result) => {
        this.base64textString = result['logo'];
      }, (err) => {
        this.toastr.error(err.error.message, 'Erro', {
          timeOut: 5000,
        })
      });
    }

    this.getBancos();
    this.getBancoConta();
    this.getParametrosWebHook();
    this.recuperarObetoCobranca();
    this.getParametros();
  }

  get f() {
    return this.stepOneForm.controls;
  }

  get i() {
    return this.stepWebHook.controls;
  }

  getBancoConta() {

    this.bancoContaList = [];

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    this.financeiroService.getBancoConta(currentUser['minhaConta']['id'], this.numberItemsPageBancos, this.currentPageBancos).subscribe((result) => {

      result['content'].forEach(element => {
        this.bancoContaList.push(element);
      });
      this.totalBancos = result['totalElements'];
      this.totalPagesBancos = result['totalPages'];
      this.currentPageBancos = result['pageable']['pageNumber'];

      this.numberButtonsPaginationBancos = Array.from(new Array(this.totalPagesBancos), (x, i) => i + 1);

    }, (err) => {
      this.error(err);
    });
  }

  getParametrosWebHook() {

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    this.financeiroService.getParametrosWebHook(currentUser['minhaConta']['id']).subscribe((result) => {     
      this.parametros = result;

      this.parametros.forEach(element => {               
        if(element.chave == "WEBHOOK_COBRANCA"){
          this.stepWebHook.controls['ativar'].setValue(element.valor);
        }
        if(element.chave == "WEBHOOK_COBRANCA_URL"){
          this.stepWebHook.controls['url'].setValue(element.valor);
        }
        if(element.chave == "WEBHOOK_COBRANCA_EMAIL"){
          this.stepWebHook.controls['email'].setValue(element.valor);
        }
        if(element.chave == "WEBHOOK_COBRANCA_TOKEN"){
          this.stepWebHook.controls['token'].setValue(element.valor);
        }
        if(element.chave == "WEBHOOK_COBRANCA_STATUS_FILA"){
          this.stepWebHook.controls['status_fila'].setValue(element.valor);
        }
      });

    }, (err) => {
      this.error(err);
    });
  }

  changePageConta(targetPage) {
    this.currentPageBancos = targetPage;
    this.getBancoConta();
  }

  openDeletetModelConta(DeleteModalContent, contaId) {
    this.modalService.open(DeleteModalContent, { windowClass: 'animated fadeInDown' });
    this.idContaToDelete = contaId;
  }

  inactiveConta() {    
    this.financeiroService.inactiveConta(this.idContaToDelete).subscribe((result) => {
      this.toastr.success("Conta removida com sucesso!", 'Sucesso', {
        timeOut: 5000,
      });
      this.modalService.dismissAll();
      this.getBancoConta();
    });
  }

  getBancos() {
    this.financeiroService.getBanco().subscribe((result) => {
      result['content'].forEach(element => {
        this.bancoList.push(element);                
      });      
      
    }, (err) => {
      this.error(err);
    });
  }

  error(erro) {
    if (erro.error.error == "Unauthorized") {
      this.toastr.error("Sua sessão expirou, por favor faça login no sistema novamente!", 'Erro', {
        timeOut: 5000,
      })
      sessionStorage.setItem('currentUser', '');
      sessionStorage.setItem('userToken', '');
      this.router.navigate(['/']);
    } else {
      this.toastr.error(erro.error.message, 'Erro', {
        timeOut: 5000,
      })
    }
  }

  cadastrarConta(){
    this.spinner.show()
    const currentUserData = JSON.parse(sessionStorage.getItem("currentUser"));

    let conta = {
      "agencia": "string",
      "descricao": "string",
      "financeiroBanco": {
        "id": 0
      },
      "minhaConta": {
        "id": 0
      },
      "minhaContaEmpresa": {
        "id": 0
      },
      "minhaContaUsuario": {
        "id": 0
      },
      "nrConta": "string",
      "saldoInicial": 0,
      "status": "A"
    }

    conta['agencia']  = this.stepOneForm.controls['agencia'].value;
    conta['nrConta']  = this.stepOneForm.controls['nr_conta'].value;
    conta['financeiroBanco']['id']  = this.stepOneForm.controls['banco'].value;

    this.bancoList.forEach(element => {          
      if(element.id == this.stepOneForm.controls['banco'].value){
        conta['descricao'] = element.codigo+" - "+element.descricao
      }
    });

    conta['minhaConta']['id'] = currentUserData['minhaConta']['id'];
    conta['minhaContaUsuario']['id'] = currentUserData['id'];

    this.financeiroService.addBancoConta(conta).subscribe((result) => {
      this.toastr.success("Conta cadastrada com sucesso!", 'Sucesso', {
        timeOut: 5000,
      });
      this.getBancoConta();
      this.spinner.hide()
      this.stepOneForm.reset();
    }, (err) => {
      this.toastr.error(err.error.message, 'Erro', {
        timeOut: 5000,
      })
      this.spinner.hide()
    });    
  }

  cadastrarWebHook(){
    this.parametrosWebHookEnviar = [];
    this.parametros.forEach(element => {               
      if(element.chave == "WEBHOOK_COBRANCA"){
        element.valor = this.stepWebHook.controls['ativar'].value;
        this.parametrosWebHookEnviar.push(element);
      }
      if(element.chave == "WEBHOOK_COBRANCA_URL"){
        element.valor = this.stepWebHook.controls['url'].value;
        this.parametrosWebHookEnviar.push(element);
      }
      if(element.chave == "WEBHOOK_COBRANCA_EMAIL"){
        element.valor = this.stepWebHook.controls['email'].value;
        this.parametrosWebHookEnviar.push(element);
      }
      if(element.chave == "WEBHOOK_COBRANCA_TOKEN"){
        element.valor = this.stepWebHook.controls['token'].value;
        this.parametrosWebHookEnviar.push(element);
      }
      if(element.chave == "WEBHOOK_COBRANCA_STATUS_FILA"){
        element.valor = this.stepWebHook.controls['status_fila'].value;
        this.parametrosWebHookEnviar.push(element);
      }
    });
    
    let objEnviar = {
      'contaParametros': this.parametrosWebHookEnviar
    }

    this.financeiroService.cadastrarWebHook(objEnviar).subscribe((result) => {
      this.toastr.success("Dados Atualizados com sucesso!", 'Sucesso', {
        timeOut: 5000,
      });
      this.getBancoConta();
    }, (err) => {
      this.toastr.error(err.error.message, 'Erro', {
        timeOut: 5000,
      })
    }); 
  }

  recuperarObetoCobranca(){
    this.httpClient.get('./assets/cobranca.json').subscribe((result) => {
      this.cobranca = result;
    }, (err) => {
      this.toastr.error(err.error.message, 'Erro', {
        timeOut: 5000,
      })
    }); 
  }

  getParametros() {
    const currentUserData = JSON.parse(sessionStorage.getItem("currentUser"));
    this.parametrosList = [];
    this.minhacContaService.getParametros(currentUserData['minhaConta']['id']).subscribe((result) => {
      result['content'].forEach(element => {
        this.parametrosList.push(element);
      });
      
    }, (err) => {
      this.error(err);
    });
  }

  editarParametroN(parametro) {
    this.dataParametro['chave'] = parametro.chave;
    this.dataParametro['valor'] = "N";

    this.minhacContaService.editParametro(parametro.id, this.dataParametro).subscribe((result) => {
      this.toastr.success("Parâmetro alterado com sucesso!", 'Sucesso', {
        timeOut: 5000,
      });

      this.getParametros();

    }, (err) => {
      this.toastr.error(err.error.message, 'Erro', {
        timeOut: 5000,
      })
    });

  }

  editarParametroS(parametro) {
    this.dataParametro['chave'] = parametro.chave;
    this.dataParametro['valor'] = "S";

    this.minhacContaService.editParametro(parametro.id, this.dataParametro).subscribe((result) => {
      this.toastr.success("Parâmetro alterado com sucesso!!", 'Sucesso', {
        timeOut: 5000,
      });

      this.getParametros();

    }, (err) => {
      this.toastr.error(err.error.message, 'Erro', {
        timeOut: 5000,
      })
    });
  }

  editarParametro(parametro) {

    this.dataParametro['chave'] = parametro.chave;
    this.dataParametro['valor'] = parametro.valor;

    this.minhacContaService.editParametro(parametro.id, this.dataParametro).subscribe((result) => {
      this.toastr.success("Parâmetro alterado com sucesso!!", 'Sucesso', {
        timeOut: 5000,
      });

      this.getParametros();

    }, (err) => {
      this.toastr.error(err.error.message, 'Erro', {
        timeOut: 5000,
      })
    });
  }

  onUploadChange(evt: any) {
    const file = evt.target.files[0];

    let img = new Image()
    img.src = window.URL.createObjectURL(evt.target.files[0])

    img.onload = () => {
      this.imagemDescricaoTamaho = "Altura: "+img.height+",  Largura: "+img.width;
   }       
  
    if (file) {
      const reader = new FileReader();
  
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }
  
  handleReaderLoaded(e) {
    this.base64textString = 'data:image/jpg;base64,' + btoa(e.target.result);        
    this.tamanhoImagem = 1;   
  }

  salvarimagem(){
    let logo = {
      'logo': this.base64textString
    }

    this.minhacContaService.editLogo(this.usuario.minhaConta.id, logo).subscribe((result) => {
      this.toastr.success("Logo alterada com sucesso!!", 'Sucesso', {
        timeOut: 5000,
      });
      location.reload();
    }, (err) => {
      this.toastr.error(err.error.message, 'Erro', {
        timeOut: 5000,
      })
    });
  }

  atualizarMinhaConta(){

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    let minhaConta = {
      id: currentUser['minhaConta']['id'],
      descricao: this.pessoaForm.controls['descricao'].value,
      cpfCnpj: this.pessoaForm.controls['cpfCnpj'].value,
      nomeFantasia: this.pessoaForm.controls['nomeFantasia'].value,
      celular: this.pessoaForm.controls['celular'].value,
      cep: this.pessoaForm.controls['cep'].value,
      cidade: this.pessoaForm.controls['cidade'].value,
      logradouro: this.pessoaForm.controls['logradouro'].value,
      bairro: this.pessoaForm.controls['bairro'].value,
      numero: this.pessoaForm.controls['numero'].value,
      complemento: this.pessoaForm.controls['complemento'].value
    }

    this.minhacContaService.editarMinhaConta(currentUser['minhaConta']['id'], minhaConta).subscribe((result) => {
      this.toastr.success("Conta atualizada com sucesso!", 'Sucesso', {
        timeOut: 5000,
      });
    }, (err) => {
      this.toastr.error(err.error.message, 'Erro', {
        timeOut: 5000,
      })
    });    
  }

}
