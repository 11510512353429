import { Injectable } from '@angular/core';
import { api_cep } from '../../_configs/configurations';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CepService {

  constructor(private http: HttpClient) { }


  getDataAddressbyCep(cep){
    return this.http.get(`${api_cep}${cep}/json/`, {

    });
  }

}
