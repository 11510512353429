//URL BASE DA API
//export const base_url = "https://homolog-sgpayapi.datalabs.com.br/sgpay-api/v1/";
export const base_url = "https://api.sellouspay.com.br/sellouspay-api/v1/"; //Produção 
//export const base_url = "http://localhost:7500/sellouspay-api/v1/"; //Local 

//URL da API de CEP
export const api_cep = "https://viacep.com.br/ws/";

//URL do FRONT
export const front_url = "https://app.sellouspay.com.br";
//export const front_url = "http://localhost:4200";
