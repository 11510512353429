<div class="app-content content">
  <div class="content-wrapper">
    <div class="card">
      <div class="card-content">
        <div class="card-body">

          <section id="basic-tabs-components">
            <div class="row" matchHeight="card">
              <div class="col-xl-12 col-lg-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title">Meu Perfil</h4>
                  </div>
                  <div class="card-content">
                    <div class="card-body">

                      <ngb-tabset>
                        <!-- <div class="nav nav-tabs"> -->
                        <!-- <ngb-tab title="Meus Dados">
                          <ng-template ngbTabContent>
                            Oat cake marzipan cake lollipop caramels wafer pie jelly beans. Icing halvah chocolate cake
                            carrot cake. Jelly beans carrot cake marshmallow gingerbread chocolate cake. Gummies cupcake
                            croissant..
                          </ng-template>
                        </ngb-tab> -->
                        <ngb-tab title="Meus Dados">
                          <ng-template ngbTabContent>

                            <div class="card">
                              <div class="card-content">                            
                                    <p style="border-bottom: 1px solid; margin-top: 20px; margin-bottom: 20px;"><b>Minha Logo</b></p>
                                    <div class="col-xl-4 col-md-6 col-12">
                                      <div class="card">
                                        <div class="text-center">
                                          <div class="card-body">
                                            <!-- <img *ngIf="base64textString == null" src="assets/images/logo/logo_02.png" class="rounded-circle  height-150"
                                              alt="Card image">                                               -->
                                            <img *ngIf="base64textString != null" src="{{base64textString}}" class="rounded-circle  height-150"
                                            alt="Card image">
                                          </div>                                        
                                          <!-- <div class="form-actions text-left">
                                            <input type="file" class="btn btn-light" (change)="onUploadChange($event)" accept=".jpeg, .jpg"  style="color: black;"/>
                                            <br>
                                            <button type="button" 
                                                class="btn btn-light"  style="color: black;">
                                                <i class="la la-check"></i> Salvar
                                            </button>
                                        </div> -->



                                        <div class="row">

                                          <div class="col-md-6">
                                              <!-- <label class="label-control" for="descricao">Nome Completo</label> -->
                                              <input type="file" class="btn btn-light" (change)="onUploadChange($event)" accept=".jpeg, .jpg, .png"  style="color: black;"/>
                                              <p *ngIf="tamanhoImagem == 0"
                                                  class="block-tag text-left"><small
                                                      class="badge badge-default badge-danger">Por favor, selecione uma imagem!</small>
                                              </p>
                                              <p *ngIf="tamanhoImagem == 1"
                                                  class="block-tag text-left"><small
                                                      class="badge badge-default badge-danger">{{imagemDescricaoTamaho}}</small>
                                              </p>
                                          </div>

                                        <div class="col-md-7">
                                          <button *ngIf="tamanhoImagem == 1" (click)="salvarimagem()" type="button" 
                                              class="btn btn-light"  style="color: black;">
                                              <i class="la la-check"></i> Salvar
                                          </button>
                                        </div>
      
      
                                      </div>




                                        </div>
                                      </div>
                                    </div>                      
                              </div>
                            </div>

                            <div class="card">
                              <div class="card-content">
                                <div class="card-body">

                                  <ng-container mCardBody>

                                    <form class="form form-horizontal row-separator" [formGroup]="pessoaForm">
                                      <div class="form-body">
                                        <h4 class="form-section"><i
                                            class="la la-pencil"></i>
                                          Meus Dados</h4>
                                        <div class="row">

                                          <div class="col-md-4">
                                            <label class="label-control" for="descricao">Descrição</label>
                                            <input type="text" class="form-control border-primary"
                                              placeholder="Digite a descrição" formControlName="descricao">
                                            <p *ngIf="pessoaForm.get('descricao').touched && pessoaForm.get('descricao').hasError('required')"
                                              class="block-tag text-left"><small
                                                class="badge badge-default badge-danger">Por favor, digite o
                                                nome completo!</small>
                                            </p>
                                          </div>

                                          <div class="col-md-4">
                                            <label class="label-control" for="nomeFantasia">Apelido / Nome Fantasia</label>
                                            <input type="text" class="form-control border-primary"
                                              placeholder="Digite o apelido ou o nome fantasia" formControlName="nomeFantasia">
                                            <p *ngIf="pessoaForm.get('nomeFantasia').touched && pessoaForm.get('nomeFantasia').hasError('required')"
                                              class="block-tag text-left"><small
                                                class="badge badge-default badge-danger">Por favor, digite o apelido ou o nome fantasia!</small>
                                            </p>
                                          </div>

                                          <div class="col-md-4">
                                            <label class="label-control" for="email">E-mail</label>
                                            <input type="text" class="form-control border-primary"
                                              placeholder="Digite o E-mail" formControlName="email" disabled>
                                            <p *ngIf="pessoaForm.get('email').touched && pessoaForm.get('email').hasError('required')"
                                              class="block-tag text-left"><small
                                                class="badge badge-default badge-danger">Por favor, digite o
                                                e-mail!</small>
                                            </p>
                                          </div>

                                          <div class="col-md-4">
                                            <label class="label-control" for="cpfCnpj">CPF/CNPJ</label>
                                            <input type="text" class="form-control border-primary"
                                              placeholder="Digite o CPF ou CNPJ" formControlName="cpfCnpj" [mask]="maskCpfCnpj" disabled>
                                            <p *ngIf="pessoaForm.get('cpfCnpj').touched && pessoaForm.get('cpfCnpj').hasError('required')"
                                              class="block-tag text-left"><small
                                                class="badge badge-default badge-danger">Por favor, digite o cpf ou cnpj!</small>
                                            </p>
                                          </div>

                                          <div class="col-md-4">
                                            <label class="label-control" for="celular">Celular</label>
                                            <input type="text" class="form-control border-primary"
                                              placeholder="Digite o Celular" formControlName="celular" mask="(00) 00000-0000">
                                            <p *ngIf="pessoaForm.get('celular').touched && pessoaForm.get('celular').hasError('required')"
                                              class="block-tag text-left"><small
                                                class="badge badge-default badge-danger">Por favor, digite o celular!</small>
                                            </p>
                                          </div>

                                          <div class="col-md-4">
                                            <label class="label-control" for="cep">CEP</label>
                                            <input type="text" class="form-control border-primary"
                                              placeholder="Digite o CEP" formControlName="cep" mask="00000-000">
                                            <p *ngIf="pessoaForm.get('cep').touched && pessoaForm.get('cep').hasError('required')"
                                              class="block-tag text-left"><small
                                                class="badge badge-default badge-danger">Por favor, digite o CEP!</small>
                                            </p>
                                          </div>

                                          <div class="col-md-4">
                                            <label class="label-control" for="logradouro">Logradouro</label>
                                            <input type="text" class="form-control border-primary"
                                              placeholder="Digite o Logradouro" formControlName="logradouro">
                                            <p *ngIf="pessoaForm.get('logradouro').touched && pessoaForm.get('logradouro').hasError('required')"
                                              class="block-tag text-left"><small
                                                class="badge badge-default badge-danger">Por favor, digite o logradouro!</small>
                                            </p>
                                          </div>

                                          <div class="col-md-4">
                                            <label class="label-control" for="numero">Número</label>
                                            <input type="text" class="form-control border-primary"
                                              placeholder="Digite o Número" formControlName="numero">
                                            <p *ngIf="pessoaForm.get('numero').touched && pessoaForm.get('numero').hasError('required')"
                                              class="block-tag text-left"><small
                                                class="badge badge-default badge-danger">Por favor, digite o número!</small>
                                            </p>
                                          </div>

                                          <div class="col-md-4">
                                            <label class="label-control" for="complemento">Complemento</label>
                                            <input type="text" class="form-control border-primary"
                                              placeholder="Digite o Complemento" formControlName="complemento">
                                            <p *ngIf="pessoaForm.get('complemento').touched && pessoaForm.get('complemento').hasError('required')"
                                              class="block-tag text-left"><small
                                                class="badge badge-default badge-danger">Por favor, digite o complemento!</small>
                                            </p>
                                          </div>

                                          <div class="col-md-4">
                                            <label class="label-control" for="bairro">Bairro</label>
                                            <input type="text" class="form-control border-primary"
                                              placeholder="Digite o Bairro" formControlName="bairro">
                                            <p *ngIf="pessoaForm.get('bairro').touched && pessoaForm.get('bairro').hasError('required')"
                                              class="block-tag text-left"><small
                                                class="badge badge-default badge-danger">Por favor, digite o bairro!</small>
                                            </p>
                                          </div>

                                          <div class="col-md-4">
                                            <label class="label-control" for="cidade">Cidade</label>
                                            <input type="text" class="form-control border-primary"
                                              placeholder="Digite o Cidade" formControlName="cidade">
                                            <p *ngIf="pessoaForm.get('cidade').touched && pessoaForm.get('cidade').hasError('required')"
                                              class="block-tag text-left"><small
                                                class="badge badge-default badge-danger">Por favor, digite a cidade!</small>
                                            </p>
                                          </div>

                                        </div>
                                      </div>

                                      <div class="form-actions text-right">
                                        <button type="button" [disabled]="!pessoaForm.valid" (click)="atualizarMinhaConta()"
                                          class="btn btn-primary">
                                          <i class="la la-check"></i> Atualizar Dados
                                        </button>
                                      </div>

                                    </form>
                                  </ng-container>

                                </div>
                              </div>
                            </div>


                          </ng-template>
                        </ngb-tab>
                        <ngb-tab>
                          <ng-template ngbTabTitle>Conta Bancária</ng-template>
                          <ng-template ngbTabContent>

                            <div class="card">
                              <div class="card-content">
                                <div class="card-body">
                                  <ng-container mCardBody>
                                    <form class="form form-horizontal row-separator" [formGroup]="stepOneForm">
                                      <div class="form-body">
                                        <h4 class="form-section"><i class="la la-pencil"></i>
                                          Conta Bancária</h4>
                                        <div class="row">
                                          <div class='col-md-6'>

                                            <div class="form-group">
                                              <label style="font-weight: bold;" class="form-control-label"
                                                for="banco">Escolha o Banco</label>
                                              <select class="form-control input-md" formControlName="banco"
                                                [ngClass]="{ 'is-invalid': f.banco.errors }">
                                                <option *ngFor="let banco of bancoList" [value]="banco.id">
                                                  {{banco.codigo}} - {{banco.descricao}}</option>
                                              </select>
                                              <small class="form-text text-muted danger" *ngIf="f.banco.errors"
                                                class="invalid-feedback">
                                                <div
                                                  *ngIf="f.banco.errors.required && (f.banco.dirty || f.banco.touched)">
                                                  Campo obrigatório</div>
                                              </small>
                                            </div>

                                            <div class="form-group">
                                              <label style="font-weight: bold;" class="form-control-label"
                                                for="nr_conta">Número da Conta :</label>
                                              <input class="form-control" type="text" formControlName="nr_conta"
                                                [ngClass]="{ 'is-invalid': f.nr_conta.errors }" />
                                              <small class="form-text text-muted danger" *ngIf="f.nr_conta.errors"
                                                class="invalid-feedback">
                                                <div
                                                  *ngIf="f.nr_conta.errors.required && (f.nr_conta.dirty || f.nr_conta.touched)">
                                                  Este campo é obrigatório</div>
                                              </small>
                                            </div>
                                          </div>

                                          <div class='col-md-6'>

                                            <div class="form-group">
                                              <label style="font-weight: bold;" class="form-control-label"
                                                for="agencia">Agencia :</label>
                                              <input class="form-control" type="text" formControlName="agencia"
                                                [ngClass]="{ 'is-invalid': f.agencia.errors }" />
                                              <small class="form-text text-muted danger" *ngIf="f.agencia.errors"
                                                class="invalid-feedback">
                                                <div
                                                  *ngIf="f.agencia.errors.required && (f.agencia.dirty || f.agencia.touched)">
                                                  Este campo é obrigatório</div>
                                              </small>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-actions text-right">
                                          <button (click)="cadastrarConta()" type="button"
                                            [disabled]="!stepOneForm.valid" class="btn btn-primary">
                                            <i class="la la-check"></i> Cadastrar Conta
                                          </button>
                                        </div>
                                      </div>
                                    </form>

                                    <section>
                                      <div class="row">
                                        <div class="col-12">
                                          <div class="table-responsive">

                                            <div class="card">
                                              <p-table #dt [value]="bancoContaList"
                                                styleClass="p-datatable-responsive-demo p-datatable-gridlines p-datatable-sm"
                                                [filterDelay]="0" [globalFilterFields]="['nrDocumento']">
                                                <ng-template pTemplate="caption">
                                                  <div class="p-d-flex">
                                                    <span class="p-input-icon-left p-ml-auto">
                                                      <input pInputText type="text"
                                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                                        placeholder="Filtrar Conta" />
                                                    </span>

                                                    <!-- <a *ngIf="ativarFormDocumento == 0"
                                                                      class="btn btn-social-icon mr-1 mb-1 btn-outline-pinterest"
                                                                      style="float: right;"><span class="la la-plus-circle"
                                                                          (click)="expandirFormDocumento()"
                                                                          style="color: brown;"></span></a>
                                                                  <a *ngIf="ativarFormDocumento == 1"
                                                                      class="btn btn-social-icon mr-1 mb-1 btn-outline-pinterest"
                                                                      style="float: right;"><span class="la la-minus-circle"
                                                                          (click)="retrairFormDocumento()"
                                                                          style="color: brown;"></span></a> -->

                                                  </div>
                                                </ng-template>
                                                <ng-template pTemplate="header">
                                                  <tr id="tabelaEndereco">
                                                    <th pSortableColumn="nrDocumento" style="width: 400px;">
                                                      Descrição <p-sortIcon field="nrDocumento"></p-sortIcon>
                                                    </th>
                                                    <th scope="col">Agência</th>
                                                    <th scope="col">Conta</th>
                                                    <th scope="col">Ações</th>
                                                  </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-conta>
                                                  <tr id="tabelaEndereco">
                                                    <td scope="row">{{conta['descricao']}}</td>
                                                    <td scope="row">{{conta['agencia']}}</td>
                                                    <td scope="row">{{conta['nrConta']}}</td>
                                                    <td><i style="color: #1E90FF;" (click)="editDocumento(documento)"
                                                        class="icon-pencil icon-btn-actions"
                                                        triggers="hover:click:hover" [autoClose]="'outside'"
                                                        ngbTooltip="Editar Documento"></i>
                                                      <i style="color: 	#B22222; margin-left: 10px;"
                                                        (click)="openDeletetModelConta(DeleteModalContentConta, conta['id'])"
                                                        class="icon-trash icon-btn-actions" triggers="hover:click:hover"
                                                        [autoClose]="'outside'" ngbTooltip="Excluir Documento"></i>
                                                    </td>
                                                  </tr>
                                                </ng-template>
                                                <ng-template pTemplate="summary">
                                                  <div class="p-d-flex p-ai-center p-jc-between">
                                                    Total de Contas ({{totalBancos}})
                                                  </div>
                                                </ng-template>
                                              </p-table>
                                            </div>

                                            <p *ngIf="totalDocumentos == 0" style="text-align: center;"><i>Nenhuma conta
                                                cadastrada até o momento</i></p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-12">
                                          <nav aria-label="Page navigation example">
                                            <ul class="pagination">
                                              <li *ngFor="let page of numberButtonsPaginationBancos, let i = index"
                                                [ngClass]="{'active': i == currentPageBancos} "
                                                (click)="changePageConta(i)" class="page-item "><a
                                                  class="page-link">{{page}}</a></li>
                                              <!-- <li class="page-item active"><a class="page-link" href="#">2</a></li> -->
                                            </ul>
                                          </nav>
                                        </div>
                                      </div>

                                    </section>

                                  </ng-container>

                                </div>
                              </div>
                            </div>

                          </ng-template>
                        </ngb-tab>
                        <ngb-tab title="Webhook para cobranças">
                          <ng-template ngbTabContent>

                            <div class="card">
                              <div class="card-content">
                                <div class="card-body">
                                  <ng-container mCardBody>
                                    <form class="form form-horizontal row-separator" [formGroup]="stepWebHook">
                                      <div class="form-body">
                                        <h4 class="form-section"><i class="la la-pencil"></i>
                                          Webhook (POST)</h4>
                                        <div class="row">
                                          <div class='col-md-12'>

                                            <div class="form-group">
                                              <label style="font-weight: bold;" class="form-control-label"
                                                for="ativar">Ativar Webhook:</label>
                                              <select class="form-control input-md" formControlName="ativar"
                                                [ngClass]="{ 'is-invalid': i.ativar.errors }">
                                                <option value="S">SIM</option>
                                                <option value="N">NÃO</option>
                                              </select>
                                              <small class="form-text text-muted danger" *ngIf="i.ativar.errors"
                                                class="invalid-feedback">
                                                <div
                                                  *ngIf="i.ativar.errors.required && (i.ativar.dirty || i.ativar.touched)">
                                                  Campo obrigatório</div>
                                              </small>
                                            </div>

                                            <div class="form-group">
                                              <label style="font-weight: bold;" class="form-control-label" for="url">URL
                                                :</label>
                                              <input class="form-control" type="text" formControlName="url"
                                                [ngClass]="{ 'is-invalid': i.url.errors }" />
                                              <small class="form-text text-muted danger" *ngIf="i.url.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="i.url.errors.required && (i.url.dirty || i.url.touched)">
                                                  Este campo é obrigatório</div>
                                              </small>
                                            </div>

                                            <div class="form-group">
                                              <label style="font-weight: bold;" class="form-control-label"
                                                for="email">E-mail :</label>
                                              <input class="form-control" type="text" formControlName="email"
                                                [ngClass]="{ 'is-invalid': i.email.errors }" />
                                              <small class="form-text text-muted danger" *ngIf="i.email.errors"
                                                class="invalid-feedback">
                                                <div
                                                  *ngIf="i.email.errors.required && (i.email.dirty || i.email.touched)">
                                                  Este campo é obrigatório</div>
                                              </small>
                                            </div>

                                            <div class="form-group">
                                              <label style="font-weight: bold;" class="form-control-label"
                                                for="token">Token (Authorization) :</label>
                                              <input class="form-control" type="text" formControlName="token"
                                                [ngClass]="{ 'is-invalid': i.token.errors }" />
                                              <small class="form-text text-muted danger" *ngIf="i.token.errors"
                                                class="invalid-feedback">
                                                <div
                                                  *ngIf="i.token.errors.required && (i.token.dirty || i.token.touched)">
                                                  Este campo é obrigatório</div>
                                              </small>
                                            </div>

                                            <div class="form-group">
                                              <label style="font-weight: bold;" class="form-control-label"
                                                for="status_fila">Status da Fila:</label>
                                              <select class="form-control input-md" formControlName="status_fila"
                                                [ngClass]="{ 'is-invalid': i.status_fila.errors }">
                                                <option value="A">Ativo</option>
                                                <option value="I">Inativo</option>
                                              </select>
                                              <small class="form-text text-muted danger" *ngIf="i.status_fila.errors"
                                                class="invalid-feedback">
                                                <div
                                                  *ngIf="i.status_fila.errors.required && (i.status_fila.dirty || i.status_fila.touched)">
                                                  Campo obrigatório</div>
                                              </small>
                                            </div>

                                          </div>
                                        </div>
                                        <div class="form-actions text-right">
                                          <button (click)="cadastrarWebHook()" type="button"
                                            [disabled]="!stepWebHook.valid" class="btn btn-primary">
                                            <i class="la la-check"></i> Salvar
                                          </button>
                                        </div>
                                      </div>



                                      <label style="font-weight: bold;" class="form-control-label" for="token">Retorno
                                        WebHook</label><br>
                                      <label style="font-weight: bold;" class="form-control-label" for="token">1 -
                                        Quitação</label><br>
                                      <label style="font-weight: bold;" class="form-control-label" for="token">2 -
                                        Cancelamento</label><br>
                                      <label style="font-weight: bold;" class="form-control-label" for="token">3 - Em
                                        processamento</label><br>
                                      <label style="font-weight: bold;" class="form-control-label" for="token">4 -
                                        Alteração de data de vencimento</label><br>
                                      <label style="font-weight: bold;" class="form-control-label" for="token">5 -
                                          Em atraso</label><br><br>
                                      <label style="font-weight: bold;" class="form-control-label" for="token">Objeto de
                                        Envio</label>
                                      <pre [innerHTML]="cobranca | json">
                                      </pre>


                                    </form>

                                  </ng-container>

                                </div>
                              </div>
                            </div>


                          </ng-template>
                        </ngb-tab>
                        <ngb-tab title="Meus Parâmetros">
                          <ng-template ngbTabContent>

                            <ng-container mCardBody>

                              <ngb-tabset>
                                <ngb-tab title="Aparência">
                                  <ng-template ngbTabContent>        
                                    <ng-container mCardBody>
                                     <br><br>
                                      <div class="row" id="checkbox">
                                        <div class="col-12">
                                          <div class="card">
                                            <div class="card-content collapse show">
                                              <div class="table-responsive ">
                                                <table class="table table-bordered mb-0">
                                                  <tbody>
        
                                                    <tr *ngFor="let parametro of parametrosList">
                                                      <td *ngIf="parametro.grupo == 'Aparência'">
                                                        {{parametro.titulo}}<br>
                                                      </td>
                                                      <td *ngIf="parametro.grupo == 'Aparência'">
                                                        <label>
                                                          <input *ngIf="parametro.valor == 'S'" type="checkbox"
                                                            value="parametro.chave" checked
                                                            (click)="editarParametroN(parametro)">
                                                          <input *ngIf="parametro.valor == 'N'" type="checkbox"
                                                            value="parametro.chave" (click)="editarParametroS(parametro)">
        
                                                          <input
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N' && parametro.chave == 'DESCONTO_SELLOUSPAY'"
                                                            type="text" class="border-primary" [(ngModel)]="parametro.valor"
                                                            disabled>
        
                                                          <input
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N' && parametro.chave != 'DESCONTO_SELLOUSPAY'"
                                                            type="text" class="border-primary" [(ngModel)]="parametro.valor">
        
                                                            <input
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N' && parametro.chave == 'FATURA_IMPRIME_COR'"
                                                            type="color" class="border-primary" id="color-input" [(ngModel)]="parametro.valor">
        
                                                          <i (click)="editarParametro(parametro)"
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N'"
                                                            style="color: #1E90FF; margin-left: 10px; cursor: pointer;"
                                                            class="icon-pencil icon-btn-actions" triggers="hover:click:hover"
                                                            [autoClose]="'outside'" ngbTooltip="Editar Parâmetro"> Salvar</i>
        
                                                        </label>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      
                                    </ng-container>
                                  </ng-template>
                                </ngb-tab>
                                <ngb-tab title="Régua de Cobrança">
                                  <ng-template ngbTabContent>        
                                    <ng-container mCardBody>
                                      
                                      <br><br>
                                      <div class="row" id="checkbox">
                                        <div class="col-12">
                                          <div class="card">
                                            <div class="card-content collapse show">
                                              <div class="table-responsive ">
                                                <table class="table table-bordered mb-0">
                                                  <tbody>
        
                                                    <tr *ngFor="let parametro of parametrosList">
                                                      <td *ngIf="parametro.grupo == 'Régua de Cobrança'">
                                                        {{parametro.titulo}}<br>
                                                      </td>
                                                      <td *ngIf="parametro.grupo == 'Régua de Cobrança'">
                                                        <label>
                                                          <input *ngIf="parametro.valor == 'S'" type="checkbox"
                                                            value="parametro.chave" checked
                                                            (click)="editarParametroN(parametro)">
                                                          <input *ngIf="parametro.valor == 'N'" type="checkbox"
                                                            value="parametro.chave" (click)="editarParametroS(parametro)">
        
                                                          <input
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N' && parametro.chave == 'DESCONTO_SELLOUSPAY'"
                                                            type="text" class="border-primary" [(ngModel)]="parametro.valor"
                                                            disabled>
        
                                                          <input
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N' && parametro.chave != 'DESCONTO_SELLOUSPAY'"
                                                            type="text" class="border-primary" [(ngModel)]="parametro.valor">
        
                                                            <input
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N' && parametro.chave == 'FATURA_IMPRIME_COR'"
                                                            type="color" class="border-primary" id="color-input" [(ngModel)]="parametro.valor">
        
                                                          <i (click)="editarParametro(parametro)"
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N'"
                                                            style="color: #1E90FF; margin-left: 10px; cursor: pointer;"
                                                            class="icon-pencil icon-btn-actions" triggers="hover:click:hover"
                                                            [autoClose]="'outside'" ngbTooltip="Editar Parâmetro"> Salvar</i>
        
                                                        </label>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      
                                    </ng-container>
                                  </ng-template>
                                </ngb-tab>
                                <ngb-tab title="Regras de Taxas">
                                  <ng-template ngbTabContent>        
                                    <ng-container mCardBody>
                                      <br><br>
                                      <div class="row" id="checkbox">
                                        <div class="col-12">
                                          <div class="card">
                                            <div class="card-content collapse show">
                                              <div class="table-responsive ">
                                                <table class="table table-bordered mb-0">
                                                  <tbody>
        
                                                    <tr *ngFor="let parametro of parametrosList">
                                                      <td *ngIf="parametro.grupo == 'Regras de Taxas'">
                                                        {{parametro.titulo}}<br>
                                                      </td>
                                                      <td *ngIf="parametro.grupo == 'Regras de Taxas'">
                                                        <label>
                                                          <input *ngIf="parametro.valor == 'S'" type="checkbox"
                                                            value="parametro.chave" checked
                                                            (click)="editarParametroN(parametro)">
                                                          <input *ngIf="parametro.valor == 'N'" type="checkbox"
                                                            value="parametro.chave" (click)="editarParametroS(parametro)">
        
                                                          <input
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N' && parametro.chave == 'DESCONTO_SELLOUSPAY'"
                                                            type="text" class="border-primary" [(ngModel)]="parametro.valor"
                                                            disabled>
        
                                                          <input
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N' && parametro.chave != 'DESCONTO_SELLOUSPAY'"
                                                            type="text" class="border-primary" [(ngModel)]="parametro.valor">
        
                                                            <input
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N' && parametro.chave == 'FATURA_IMPRIME_COR'"
                                                            type="color" class="border-primary" id="color-input" [(ngModel)]="parametro.valor">
        
                                                          <i (click)="editarParametro(parametro)"
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N'"
                                                            style="color: #1E90FF; margin-left: 10px; cursor: pointer;"
                                                            class="icon-pencil icon-btn-actions" triggers="hover:click:hover"
                                                            [autoClose]="'outside'" ngbTooltip="Editar Parâmetro"> Salvar</i>
        
                                                        </label>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </ng-container>
                                  </ng-template>
                                </ngb-tab>
                                <ngb-tab title="Segurança">
                                  <ng-template ngbTabContent>        
                                    <ng-container mCardBody>
                                      <br><br>
                                      <div class="row" id="checkbox">
                                        <div class="col-12">
                                          <div class="card">
                                            <div class="card-content collapse show">
                                              <div class="table-responsive ">
                                                <table class="table table-bordered mb-0">
                                                  <tbody>
        
                                                    <tr *ngFor="let parametro of parametrosList">
                                                      <td *ngIf="parametro.grupo == 'Segurança'">
                                                        {{parametro.titulo}}<br>
                                                      </td>
                                                      <td *ngIf="parametro.grupo == 'Segurança'">
                                                        <label>
                                                          <input *ngIf="parametro.valor == 'S'" type="checkbox"
                                                            value="parametro.chave" checked
                                                            (click)="editarParametroN(parametro)">
                                                          <input *ngIf="parametro.valor == 'N'" type="checkbox"
                                                            value="parametro.chave" (click)="editarParametroS(parametro)">
        
                                                          <input
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N' && parametro.chave == 'DESCONTO_SELLOUSPAY'"
                                                            type="text" class="border-primary" [(ngModel)]="parametro.valor"
                                                            disabled>
        
                                                          <input
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N' && parametro.chave != 'DESCONTO_SELLOUSPAY'"
                                                            type="text" class="border-primary" [(ngModel)]="parametro.valor">
        
                                                            <input
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N' && parametro.chave == 'FATURA_IMPRIME_COR'"
                                                            type="color" class="border-primary" id="color-input" [(ngModel)]="parametro.valor">
        
                                                          <i (click)="editarParametro(parametro)"
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N'"
                                                            style="color: #1E90FF; margin-left: 10px; cursor: pointer;"
                                                            class="icon-pencil icon-btn-actions" triggers="hover:click:hover"
                                                            [autoClose]="'outside'" ngbTooltip="Editar Parâmetro"> Salvar</i>
        
                                                        </label>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </ng-container>
                                  </ng-template>
                                </ngb-tab>
                                <ngb-tab title="Em Desenvolvimento">
                                  <ng-template ngbTabContent>        
                                    <ng-container mCardBody>
                                      <br><br>
                                      <div class="row" id="checkbox">
                                        <div class="col-12">
                                          <div class="card">
                                            <div class="card-content collapse show">
                                              <div class="table-responsive ">
                                                <table class="table table-bordered mb-0">
                                                  <tbody>
        
                                                    <tr *ngFor="let parametro of parametrosList">
                                                      <td *ngIf="parametro.grupo == 'Em Desenvolvimento'">
                                                        {{parametro.titulo}}<br>
                                                      </td>
                                                      <td *ngIf="parametro.grupo == 'Em Desenvolvimento'">
                                                        <label>
                                                          <input *ngIf="parametro.valor == 'S'" type="checkbox"
                                                            value="parametro.chave" checked
                                                            (click)="editarParametroN(parametro)">
                                                          <input *ngIf="parametro.valor == 'N'" type="checkbox"
                                                            value="parametro.chave" (click)="editarParametroS(parametro)">
        
                                                          <input
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N' && parametro.chave == 'DESCONTO_SELLOUSPAY'"
                                                            type="text" class="border-primary" [(ngModel)]="parametro.valor"
                                                            disabled>
        
                                                          <input
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N' && parametro.chave != 'DESCONTO_SELLOUSPAY'"
                                                            type="text" class="border-primary" [(ngModel)]="parametro.valor">
        
                                                            <input
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N' && parametro.chave == 'FATURA_IMPRIME_COR'"
                                                            type="color" class="border-primary" id="color-input" [(ngModel)]="parametro.valor">
        
                                                          <i (click)="editarParametro(parametro)"
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N'"
                                                            style="color: #1E90FF; margin-left: 10px; cursor: pointer;"
                                                            class="icon-pencil icon-btn-actions" triggers="hover:click:hover"
                                                            [autoClose]="'outside'" ngbTooltip="Editar Parâmetro"> Salvar</i>
        
                                                        </label>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </ng-container>
                                  </ng-template>
                                </ngb-tab>
                                <ngb-tab title="Custos Taxas / Prazo">
                                  <ng-template ngbTabContent>        
                                    <ng-container mCardBody>
                                      <br><br>
                                      <div class="row" id="checkbox">
                                        <div class="col-12">
                                          <div class="card">
                                            <div class="card-content collapse show">
                                              <div class="table-responsive ">
                                                <table class="table table-bordered mb-0">
                                                  <tbody>
        
                                                    <tr *ngFor="let parametro of parametrosList">
                                                      <td *ngIf="parametro.grupo == 'Custos Taxas / Prazo' && parametro.chave.indexOf('TARIFA') == -1 && parametro.chave.indexOf('TAXA') == -1">
                                                        {{parametro.titulo}}<br>
                                                      </td>
                                                      <td *ngIf="parametro.grupo == 'Custos Taxas / Prazo' && parametro.chave.indexOf('TARIFA') == -1 && parametro.chave.indexOf('TAXA') == -1">
                                                        <label>
                                                          <input *ngIf="parametro.valor == 'S'" type="checkbox"
                                                            value="parametro.chave" checked
                                                            (click)="editarParametroN(parametro)">
                                                          <input *ngIf="parametro.valor == 'N'" type="checkbox"
                                                            value="parametro.chave" (click)="editarParametroS(parametro)">
        
                                                          <input
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N' && parametro.chave == 'DESCONTO_SELLOUSPAY'"
                                                            type="text" class="border-primary" [(ngModel)]="parametro.valor"
                                                            disabled>
        
                                                          <input
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N' && parametro.chave != 'DESCONTO_SELLOUSPAY'"
                                                            type="text" class="border-primary" [(ngModel)]="parametro.valor">
        
                                                            <input
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N' && parametro.chave == 'FATURA_IMPRIME_COR'"
                                                            type="color" class="border-primary" id="color-input" [(ngModel)]="parametro.valor">
        
                                                          <i (click)="editarParametro(parametro)"
                                                            *ngIf="parametro.valor !== 'S' && parametro.valor !== 'N'"
                                                            style="color: #1E90FF; margin-left: 10px; cursor: pointer;"
                                                            class="icon-pencil icon-btn-actions" triggers="hover:click:hover"
                                                            [autoClose]="'outside'" ngbTooltip="Editar Parâmetro"> Salvar</i>
        
                                                        </label>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </ng-container>
                                  </ng-template>
                                </ngb-tab>
                              </ngb-tabset>
                            </ng-container>
                          </ng-template>
                        </ngb-tab>
                      </ngb-tabset>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <ng-template #DeleteModalContentConta let-c="close" let-d="dismiss">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel1">Excluir Conta</h4>
              <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Deseja excluir esta conta?</p>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal')"
                ngbAutofocus>Cancelar</button>
              <button type="button" (click)="inactiveConta()" class="btn btn-outline-danger">Excluir</button>
            </div>
            <p *ngIf="showErrorRegister" class="no-margin-bottom"
              style="color: white; text-align: center; background-color: red;">Erro ao cadastar, preencha
              todos os campos</p>
          </ng-template>


        </div>
      </div>
    </div>
  </div>
</div>