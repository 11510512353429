import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import { UserService } from '../_api/user/user.service';
import { ToastrService } from 'ngx-toastr';
import * as firebase from 'firebase/app';
import { CepService } from '../_services/Cep/cep.service';
import { threadId } from 'worker_threads';
import { MinhacontaService } from '../_services/MinhaConta/minhaconta.service';

@Component({
  templateUrl: 'register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  public registerSteps: number = 1;

  public tipoPessoa: any = "F";

  public inputCepValue = "";
  public showCepErrorMsg: boolean = false;
  public confirmPassword: string = '';
  public buttonStep2IsEnabled: boolean = false;
  public useTermsAreRead: boolean = false;
  public personType: string = '';
  public username: string = '';
  public startFinishProcess: boolean = false;
  public isRegisteredComplete: boolean = false;
  public hasRegisterError: boolean = false;
  public maskCpfCnpj: string = '';

  public registerForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    status: new FormControl('A')
  })


  public personForm = new FormGroup({
    descricao: new FormControl('', [Validators.required]),
    nomeFantasia: new FormControl('', [Validators.required]),
    celular: new FormControl('', [Validators.required]),
    cpfCnpj: new FormControl('', [Validators.required]),
    site: new FormControl(''),
    fisicaJuridica: new FormControl('', [Validators.required])
  })

  public addressForm = new FormGroup({
    cep: new FormControl('', [Validators.minLength(8)]),
    cidade: new FormControl('', [Validators.required]),
    logradouro: new FormControl('', [Validators.required]),
    bairro: new FormControl('', [Validators.required]),
    numero: new FormControl('', [Validators.required]),
    complemento: new FormControl(''),
  })


  constructor(
    private cepService: CepService, 
    private router: Router, 
    private minhaContaService: MinhacontaService,
    private toastr: ToastrService
    ) { }

  ngOnInit() {

  }


  enableButtonToStep2() {
    if (this.registerForm.valid && this.registerForm.controls['password'].value == this.confirmPassword && this.useTermsAreRead) {
      this.buttonStep2IsEnabled = true;
    } else {
      this.buttonStep2IsEnabled = false;
    }
  }

  enableButttonToStep3() {
    if (this.personForm.valid) {
      this.registerSteps = 3;
    }
  }

  setPersonType(event) {
    const recivedValue = event.target.value;
    this.personForm.controls['fisicaJuridica'].setValue(recivedValue);
    if (event.target.value == 'F') {
      this.maskCpfCnpj = '000.000.000-00';
      this.tipoPessoa = "F";
    }
    else if (event.target.value == 'J') {
      this.maskCpfCnpj = '00.000.000/0000-00';
      this.tipoPessoa = "J";
    }
  }



  getResultCEP() {
    if (this.addressForm.controls['cep'].valid) {
      this.getDataAddress();
    }
  }

  getDataAddress() {
    this.showCepErrorMsg = false;
    this.cepService.getDataAddressbyCep(this.inputCepValue).subscribe((result) => {
      if (result['erro'] != true) {
        this.addressForm.controls['bairro'].setValue(result['bairro']);
        this.addressForm.controls['cidade'].setValue(result['localidade']);
        this.addressForm.controls['logradouro'].setValue(result['logradouro']);
      } else {
        this.showCepErrorMsg = true;
      }
    });
  }

  finaliseRegister() {

    this.startFinishProcess = true;
    this.hasRegisterError = false;

    let dataSend = Object.assign(this.registerForm.value, this.personForm.value, this.addressForm.value);
    dataSend['fone'] = this.personForm.controls['celular'].value;
    let dataSendMyAccount = dataSend;
    delete dataSendMyAccount['password'];

    this.minhaContaService.registerMyAccount(dataSendMyAccount).subscribe((result) => {

      let emrpesa = {
        "bairro": "string",
        "celular": "string",
        "cep": "string",
        "cidade": "string",
        "complemento": "string",
        "cpfCnpj": "string",
        "descricao": "string",
        "email": "string",
        "fone": "string",
        "logradouro": "string",
        "minhaConta": {
          "id": 0
        },
        "numero": "string",
        "site": "string",
        "status": "A"
      }

      emrpesa['bairro'] = dataSend.bairro;
      emrpesa['celular'] = dataSend.celular;
      emrpesa['cep'] = dataSend.cep;
      emrpesa['cidade'] = dataSend.cidade;
      emrpesa['complemento'] = dataSend.complemento;
      emrpesa['cpfCnpj'] = dataSend.cpfCnpj;
      emrpesa['descricao'] = dataSend.descricao;
      emrpesa['email'] = dataSend.email;
      emrpesa['fone'] = dataSend.fone;
      emrpesa['logradouro'] = dataSend.logradouro;
      emrpesa['minhaConta']['id'] = result['id'];
      emrpesa['numero'] = dataSend.numero;
      emrpesa['site'] = dataSend.site;


      this.minhaContaService.cadastrarMinhaContaEmpresa(emrpesa).subscribe((result) => {
      }, (error) => {
        this.startFinishProcess = false;
        this.hasRegisterError = true;
      });


      let dataRegisterAccount = {
        cpf: '',
        descricao: '',
        email: '',
        minhaConta: {
          id: '',
          descricao: ''
        },
        password: '',
        role: [

        ],
        status: "A",
        username: ""
      };

      dataRegisterAccount['cpf'] = result['cpfCnpj'];
      dataRegisterAccount['descricao'] = result['descricao'];
      dataRegisterAccount['email'] = result['email'];
      dataRegisterAccount['minhaConta']['id'] = result['id'];
      dataRegisterAccount['minhaConta']['descricao'] = result['descricao'];
      dataRegisterAccount['password'] = this.registerForm.controls['password'].value;
      dataRegisterAccount['role'].push("ROLE_USER");
      dataRegisterAccount['username'] = this.username;


      this.minhaContaService.registerUserAccount(dataRegisterAccount).subscribe((result) => {
        this.isRegisteredComplete = true;

        setTimeout(() => {
          this.redirectToLogin();
        }, 2000);

      }, (error) => {
        this.startFinishProcess = false;
        this.hasRegisterError = true;
      });


    }, (error) => {
      this.startFinishProcess = false;
      this.hasRegisterError = true;
      this.toastr.error(error.error.message, 'Erro', {
        timeOut: 5000,
      })
    });
  }

  redirectToLogin() {
    this.router.navigate(['/']);
  }


  changeSteps(jumpToStep) {
    switch (jumpToStep) {
      case 1:
        this.registerSteps = 1;
        break;
      case 2:
        this.registerSteps = 2;
        break;
      case 3:
        this.registerSteps = 3;
        break;


      default:
        break;
    }
  }

}
