<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-body">
            <!-- <section class="row flexbox-container">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
                        <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                            <div class="card-header border-0">
                                <div class="card-title text-center">
                                    <img style="width: 230px; height: auto;"
                                        src="assets/images/logo/273195_193004023_1711247377.jpg" alt="branding logo">
                                </div>
                            </div>
                            <div class="card-content">
                                <p class="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1">
                                    <span>Informações de acesso</span>
                                </p>
                                <div class="card-body">
                                    <form class="form-horizontal" [formGroup]="loginForm" (ngSubmit)="tryLogin()">
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" formControlName="username" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                                                id="user-name" placeholder="Login de acesso" required>
                                            <div class="form-control-position">
                                                <i class="la la-user"></i>
                                            </div>
                                            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                                <div *ngIf="f.username.errors.required">Digite o usuário</div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group position-relative has-icon-left has-icon-right">
                                            <input [type]="passwordFieldType" placeholder="Senha"
                                                formControlName="password" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                            <div class="form-control-position">
                                                <i class="la la-key"></i>
                                            </div>
                                            <i (click)="showOrHidePassword()"
                                                style="position: absolute; right: 0; top: 13px; margin-right: 10px;"
                                                [class]="iconChangePasswordField"></i>
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">Digite a senha</div>
                                            </div>

                                        </fieldset>
                                        <div class="form-group">
                                            <div appRecaptcha (recaptchaSuccess)="onRecaptchaSuccess($event)"></div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-md-6 col-12 text-center text-sm-left pr-0">
                                            </div>
                                            <div class="col-md-6 col-12 float-sm-left text-center text-sm-right"><a
                                                    href="recover-password.html" class="card-link">Esqueci minha
                                                    senha</a>
                                            </div>
                                        </div>
                                        <button type="submit" class="btn btn-outline-info btn-block">
                                            <i class="fa fa-refresh fa-spin" *ngIf="submitted"></i>
                                            <i class="feather ft-unlock" *ngIf="!submitted"></i> Acessar conta
                                        </button>
                                    </form>
                                </div>
                                <p class="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1">
                                    <span>Ainda não possui conta?</span>
                                </p>
                                <div class="card-body">
                                    <a [routerLink]="['/register']" class="btn btn-outline-danger btn-block"><i
                                            class="la la-user"></i> Criar uma conta</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> -->

            <section id="row flexbox-container">
                <div class="row" matchHeight="card">
                    <div class="col-md-4" *blockUI="'projectInfo'; message: 'Loading'">
                        <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                            <div class="card-header border-0">
                                <div class="card-title text-center">
                                    <img style="width: 230px; height: auto;"
                                        src="assets/images/logo/273195_193004023_1711247377.jpg" alt="branding logo">
                                    <!-- <h2>Acessar o SellousPay</h2> -->
                                </div>
                                <!-- <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                                <span>Easily Using</span></h6> -->
                            </div>
                            <div class="card-content">
                                <!-- <app-social-signin></app-social-signin> -->
                                <p class="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1">
                                    <span>Informações de acesso</span>
                                </p>
                                <div class="card-body">
                                    <form class="form-horizontal" [formGroup]="loginForm" (ngSubmit)="tryLogin()">
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" formControlName="username" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                                                id="user-name" placeholder="Login de acesso" required>
                                            <div class="form-control-position">
                                                <i class="la la-user"></i>
                                            </div>
                                            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                                <div *ngIf="f.username.errors.required">Digite o usuário</div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group position-relative has-icon-left has-icon-right">
                                            <input [type]="passwordFieldType" placeholder="Senha"
                                                formControlName="password" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                            <div class="form-control-position">
                                                <i class="la la-key"></i>
                                            </div>
                                            <i (click)="showOrHidePassword()"
                                                style="position: absolute; right: 0; top: 13px; margin-right: 10px;"
                                                [class]="iconChangePasswordField"></i>
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">Digite a senha</div>
                                            </div>

                                        </fieldset>
                                        <!-- <fieldset class="form-group position-relative has-icon-left has-icon-right">
                                        <div class="form-group" appRecaptcha (recaptchaSuccess)="onRecaptchaSuccess($event)"></div>
                                    </fieldset> -->
                                        <div class="form-group">
                                            <div appRecaptcha (recaptchaSuccess)="onRecaptchaSuccess($event)"></div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-md-6 col-12 text-center text-sm-left pr-0">
                                                <!-- <fieldset>
                                                <div class="icheckbox_square-blue" id="icheckbox" (click)="addCheckbox($event)" style="position: relative;">
                                                    <input type="checkbox" class="chk-remember"
                                                        formControlName="rememberMe" name="remember-me"
                                                        id="remember-me" style="position: absolute; opacity: 0;">
                                                    <ins class="iCheck-helper"
                                                        style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins>
                                                </div>
                                                <label class="lb-remember" for="remember-me">Remember Me</label>

                                            </fieldset> -->
                                            </div>
                                            <div class="col-md-6 col-12 float-sm-left text-center text-sm-right"><a
                                                    href="recover-password.html" class="card-link">Esqueci minha
                                                    senha</a>
                                            </div>
                                        </div>
                                        <button type="submit" class="btn btn-outline-info btn-block">
                                            <i class="fa fa-refresh fa-spin" *ngIf="submitted"></i>
                                            <i class="feather ft-unlock" *ngIf="!submitted"></i> Acessar conta
                                        </button>
                                    </form>
                                </div>
                                <p class="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1">
                                    <span>Ainda não possui conta?</span>
                                </p>
                                <div class="card-body">
                                    <a [routerLink]="['/register']" class="btn btn-outline-danger btn-block"><i
                                            class="la la-user"></i> Criar uma conta</a>
                                </div>
                                <!-- <div class="card-body">
                                <div class="row">
                                    <div class="col-md-6">
                                        <a [routerLink]="['/privacypolicy']" target="_blank">Privacy Policy</a>
                                    </div>
                                    <div class="col-md-6 terms">
                                        <a [routerLink]="['/termCondition']" target="_blank">Terms & Conditions</a>
                                    </div>
                                </div>
                            </div> -->
                            </div>
                        </div>
                    </div>


                    <div class="col-md-8" *blockUI="'userProfile'; message: 'Loading'">
                        <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                            <img style="width: auto; height: auto;" src="assets/images/backgrounds/laptop.jpg"
                                alt="branding logo">
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</div>