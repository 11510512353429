import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import { ToastrService } from 'ngx-toastr';
import { CobrancaService } from '../_services/Cobranca/cobranca.service';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  //loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  token: string;

  loginForm  = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.minLength(6))
  });

  public passwordFieldType: string = 'password';
  public iconChangePasswordField: string = 'la la-eye';

  public filters = {
    page: 0,
    size: 10
 }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    public authService: AuthService,
    private renderer: Renderer2,
    private toastr: ToastrService,
    public cobrancaService: CobrancaService,
  ) {
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });

  }

  ngOnInit() {

  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  tryLogin() {
    this.submitted = true;

    const value = {
      username: this.f.username.value,
      password: this.f.password.value,
      recaptcha: this.token
    };    

    this.authService.usernameAndPasswordLogin(value).subscribe((result) =>{
      sessionStorage.setItem('userToken', result['token']);

      sessionStorage.setItem('permissions', result['roles']);
      sessionStorage.setItem('acesso', "0");

      this.authService.getCurrentUser().subscribe((result2) =>{
        sessionStorage.setItem('currentUser', JSON.stringify(result2));

        let role = 0;
       result['roles'].forEach(element => {
         if(element == "ROLE_ADMIN"){
          role = 1;
         }
       });

       
       if(role == 1){  
        sessionStorage.setItem('acesso', "1");
        this.router.navigate(['/admin/usuarios'])
        .then(() => {
          window.location.reload();
        });
       }else{
        sessionStorage.setItem('filters', JSON.stringify(this.filters));
        sessionStorage.setItem('acesso', "0");
        this.router.navigate(['/cobrancas/cobranca-dashboard']);
       }
       
      
      });
      
      
    }, (err) =>{
      this.toastr.error(err.error.message, 'Erro', {
        timeOut: 5000,
      })
    });

  }

  // setUserInStorage(res) {
  //   if (res.user) {
  //     localStorage.setItem('currentUser', JSON.stringify(res.user));
  //   } else {
  //     localStorage.setItem('currentUser', JSON.stringify(res));
  //   }
  // }

  setUserInStorage(res) {
    
    if (res.user) {
      sessionStorage.setItem('currentUser', JSON.stringify(res.user));
    } else {
      sessionStorage.setItem('currentUser', JSON.stringify(res));
    }
  }

  showOrHidePassword(){
    this.passwordFieldType = this.passwordFieldType == 'password' ? 'text' : 'password';
    this.iconChangePasswordField = this.iconChangePasswordField == 'la la-eye'? 'la la-eye-slash' : 'la la-eye';
  }

  onRecaptchaSuccess(token: string) {
    this.token = token;
    console.log(this.token)
  }
}
