import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { base_url } from '../../_configs/configurations';


@Injectable({
  providedIn: 'root'
})
export class FinanceiroService {

  constructor(private httpClient: HttpClient, ) { }

  getUserToken(){
    return sessionStorage.getItem("userToken");
  }

  getBanco(){
    return this.httpClient.get(`${base_url}financeiroBanco/listarFinanceiroBanco?sort=id,asc`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }

  addBancoConta(data){
    return this.httpClient.post(`${base_url}financeiroBancoConta/cadastrarFinanceiroBancoConta`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  getBancoConta(contaId,paginationLimit, paginationCurrentPage){
    return this.httpClient.get(`${base_url}financeiroBancoConta/listarFinanceiroBancoConta?contaId=${contaId}&page=${paginationCurrentPage}&size=${paginationLimit}&sort=id,asc`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }

  inactiveConta(id){
    return this.httpClient.put(`${base_url}financeiroBancoConta/inativarFinanceiroBancoConta/${id}`, null, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  getExtrato(contaId, dates){
    return this.httpClient.get(`${base_url}financeiroExtrato/listarExtrato?contaId=${contaId}&dataInicial=${dates.dataInicial}&dataFinal=${dates.dataFinal}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }

  getParametrosWebHook(contaId){
    return this.httpClient.get(`${base_url}hebhook/listarParametrosWebHook/${contaId}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }

  cadastrarWebHook(data){
    return this.httpClient.post(`${base_url}hebhook/cadastrarParametrosWebHook`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  getExtratoSaque(){
    return this.httpClient.get(`${base_url}financeiroExtratoSaque/listarFinanceiroExtratoSaque?size=10000000&sort=id,desc`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }

  cancelarTransferencia(id){
    return this.httpClient.put(`${base_url}financeiroExtratoSaque/cancelarTransferencia/${id}`, null, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  concluirTransferencia(data){
    return this.httpClient.post(`${base_url}financeiroExtratoSaque/finalizarTransferencia`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  processarArquivoRetorno(data){
    return this.httpClient.post(`${base_url}arquivoRetorno/processar`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  listarArquivosRetorno(){
    return this.httpClient.get(`${base_url}arquivoRetorno/listar`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }

  getSaldoOperacoes(){
    return this.httpClient.get(`${base_url}financeiroExtrato/saldoOperacoes`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }
}
