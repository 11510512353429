<div class="app-content content">
    <div class="card">
        <div class="row" *ngIf="registerSteps == 1">
            <div style="background-color: #F6F9FC;" class="col-xl-4">
        <div style="background-color: #F6F9FC;" class="card-header">
            <h3>Registar com e-mail e senha</h3>
            <p class="no-margin-bottom">Crie uma conta e acesse através de seu e-mail e senha</p>
        </div>
        <div class="card-content">
            <div class="card-body">
                <form [formGroup]="registerForm">

                    <label for="singupEmail" class="form-label"><b>E-mail</b></label>
                    <input type="text" class="form-control form-register" (input)="enableButtonToStep2()" formControlName="email" id="singupEmail" aria-describedby="basic-addon3">

                    <label for="singupEmail" class="form-label"><b>Username</b></label>
                    <input type="text" class="form-control form-register" [(ngModel)]="username" [ngModelOptions]="{standalone: true}" id="singupEmail" aria-describedby="basic-addon3">

                    <label for="singupPassword" class="form-label"><b>Senha</b></label>
                    <input type="password" class="form-control form-register" (input)="enableButtonToStep2()" formControlName="password" id="singupPassword" aria-describedby="basic-addon3">
                    <p><small><i>Minímo de 6 caracteres</i></small></p>
                    <label for="singupConfirmPassword"  class="form-label"><b>Confirmar senha</b></label>
                    <input [ngClass]="{'wrongPasswordConfirm':registerForm.controls['password'].value != confirmPassword}" type="password" class="form-control form-register" (input)="enableButtonToStep2()" [(ngModel)]="confirmPassword" [ngModelOptions]="{standalone: true}" id="singupConfirmPassword" aria-describedby="basic-addon3">
                    <div class="form-check">
                        <input class="form-check-input" [(ngModel)]="useTermsAreRead" [ngModelOptions]="{standalone: true}" (change)="enableButtonToStep2()" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                          Li e concordo com os <a href="https://www.google.com" target="blank">Termos de uso</a>
                        </label>
                      </div>
                    <div class="row">
                        <div class="col-12">
                            <button type="button" [disabled]="!buttonStep2IsEnabled" (click)="changeSteps(2)" class="btn btn-primary btn-register">Criar conta</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button type="button" (click)="redirectToLogin()" class="btn btn-link">Já possuo cadastro.</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div style="background-color: #F6F9FC;" class="col-xl-2"></div>
    </div>

    <!-- Passo 2 -->

    <div class="row" *ngIf="registerSteps == 2">
        <div style="background-color: #F6F9FC;" class="col-xl-4">
    <div style="background-color: #F6F9FC;" class="card-header">
        <h3>Informações Pessoais</h3>
    </div>
    <div class="card-content">
        <div class="card-body">

            <form [formGroup]="personForm">

                <div class="card">
                    <div class="card-content">
                        <div class="card-body">

                            <div class="form-check">
                                <input class="form-check-input" (change)="setPersonType($event)" value="F" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                <label class="form-check-label" for="flexRadioDefault1">
                                    <h3>Pessoa Física</h3>
                                </label>
                              </div>
                              Pessoa autônoma sem CNPJ
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-content">
                        <div class="card-body">

                            <div class="form-check">
                                <input class="form-check-input" (change)="setPersonType($event)" value="J" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
                                <label class="form-check-label" for="flexRadioDefault2">
                                    <h3>Pessoa Juridica</h3>
                                </label>
                            </div>
                            MEI, LTDA, S/A, SS
                        </div>
                    </div>
                </div>

                <label *ngIf="tipoPessoa == 'F'" for="singupName" class="form-label"><b>Nome</b></label>
                <label *ngIf="tipoPessoa == 'J'" for="singupName" class="form-label"><b>Razão Social</b></label>
                <input type="text" maxlength="50" formControlName="descricao" class="form-control form-register" id="singupName" aria-describedby="basic-addon3">

                <label *ngIf="tipoPessoa == 'F'" for="nomeFantasia" class="form-label"><b>Apelido</b></label>
                <label *ngIf="tipoPessoa == 'J'" for="nomeFantasia" class="form-label"><b>Nome Fantasia</b></label>
                <input type="text" maxlength="50" formControlName="nomeFantasia" class="form-control form-register" id="nomeFantasia" aria-describedby="basic-addon3">

                <label for="singupPassword" class="form-label"><b>Celular</b></label>
                <input type="text" mask="(00) 00000-0000" formControlName="celular" class="form-control form-register" id="singupPassword" aria-describedby="basic-addon3">                

                <label *ngIf="tipoPessoa == 'F'" for="singupConfirmPassword" class="form-label"><b>CPF</b></label>
                <label *ngIf="tipoPessoa == 'J'" for="singupConfirmPassword" class="form-label"><b>CNPJ</b></label>
                <input type="text"  [mask]="maskCpfCnpj" formControlName="cpfCnpj" class="form-control form-register" id="singupConfirmPassword" aria-describedby="basic-addon3">

                <!-- <label for="singupConfirmPassword" class="form-label"><b>CNPJ</b></label>
                <input type="text"  mask="00.000.000/0000-00" class="form-control form-register" id="singupConfirmPassword" aria-describedby="basic-addon3"> -->

                <label for="singupConfirmPassword" class="form-label"><b>Site</b></label>
                <input type="text"  formControlName="site" class="form-control form-register" placeholder="www.meusite.com" id="singupConfirmPassword" aria-describedby="basic-addon3">
                <div class="row">
                    <div class="col-12">
                        <!-- <button type="button" (click)="changeSteps(3)" class="btn btn-primary btn-register">Avançar</button> -->
                        <button type="button" (click)="enableButttonToStep3()" class="btn btn-primary btn-register">Avançar</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button type="button" (click)="redirectToLogin()" class="btn btn-link">Já possuo cadastro.</button>
                    </div>
                </div>

            </form>
        </div>


    </div>
</div>
<div style="background-color: #F6F9FC;" class="col-xl-2"></div>
</div>

    <!-- Passo 3 -->

    
    <div class="row" *ngIf="registerSteps == 3">
        <div style="background-color: #F6F9FC;" class="col-xl-4">
    <div style="background-color: #F6F9FC;" class="card-header">
        <h3>Endereço</h3>
        <p class="no-margin-bottom">Informações relacionadas ao seu endereço</p>
    </div>
    <div class="card-content">
        <div class="card-body">
            <form [formGroup]="addressForm">
                <div class="cepArea">
                    <label for="singupConfirmPassword" class="form-label"><b>CEP</b></label>
                    <input type="text" style="width: 40%;" formControlName="cep" (input)="getResultCEP()" [(ngModel)]="inputCepValue" mask="00000-000" class="form-control form-register" id="singupConfirmPassword" aria-describedby="basic-addon3">
                    <small *ngIf="showCepErrorMsg" style="color: red;">CEP invalido</small>
                </div>
                
                <label for="singupConfirmPassword" class="form-label"><b>Cidade</b></label>
                <input type="text" class="form-control form-register" formControlName="cidade" id="singupConfirmPassword" aria-describedby="basic-addon3">

                    <label for="singupName" class="form-label"><b>Rua</b></label>
                    <input type="text" class="form-control form-register" formControlName="logradouro" id="singupName" aria-describedby="basic-addon3">

                    <label for="singupPassword" class="form-label"><b>Bairro</b></label>
                    <input type="text" class="form-control form-register" formControlName="bairro" id="singupPassword" aria-describedby="basic-addon3">

                    <div class=" row formContent">

                        <div class="col-xl-3">
                            <label for="singupConfirmPassword" class="form-label"><b>Número</b></label>
                            <input style="width: 100%;" type="text" formControlName="numero" class="form-control form-register" id="singupConfirmPassword" aria-describedby="basic-addon3">
                        </div>
                        <div class="col-xl-9">
                            <label for="singupConfirmPassword" class="form-label"><b>Complemento</b></label>
                            <input style="width: 100%;" type="text" formControlName="complemento" class="form-control form-register" id="singupConfirmPassword" aria-describedby="basic-addon3">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button type="button" [disabled]="startFinishProcess" (click)="finaliseRegister()" class="btn btn-primary btn-register">
                                <span *ngIf="!startFinishProcess">Concluir</span>
                                <div *ngIf="startFinishProcess" class="spinner-border text-light" role="status">
                                    <span class="visually-hidden"></span>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button type="button" (click)="redirectToLogin()" class="btn btn-link">Já possuo cadastro.</button>
                        </div>
                    </div>

            </form>

        </div>


    </div>

</div>

<div style="background-color: #F6F9FC;" class="col-xl-2"></div>
</div>

        <div *ngIf="isRegisteredComplete">
            <p style="text-align: center; background-color: green; margin-bottom: 0px; color: #fff">
                Cadastrado com sucesso!
            </p>
        </div>
        <div *ngIf="hasRegisterError">
            <p style="text-align: center; background-color: red; margin-bottom: 0px; color: #fff">
                Erro ao cadastrar, tente novamente mais tarde!
            </p>
        </div>
    </div>
    <div class="content-wrapper">

        <!-- <div class="content-header row mb-1">
        </div>
        <div class="content-body">
            <section class="flexbox-container">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-md-4 col-10 box-shadow-2 p-0">
                        <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                            <div class="card-header border-0 pb-0">
                                <div class="card-title text-center">
                                    <img src="assets/images/logo/logo-dark.png" alt="branding logo">
                                </div>
                                <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                                    <span>Easily Using</span></h6>
                            </div>
                            <div class="card-content">
                                <app-social-signin></app-social-signin>
                                <p class="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1">
                                    <span>OR Using Email</span></p>
                                <div class="card-body">
                                    <form class="form-horizontal" [formGroup]="registerForm" (ngSubmit)="tryRegister()">
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" formControlName="firstName" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
                                                placeholder="User Name">
                                            <div class="form-control-position">
                                                <i class="feather ft-user"></i>
                                            </div>
                                            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                                <div *ngIf="f.firstName.errors.required">Username is required</div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="email" formControlName="email" class="form-control" email
                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                                placeholder="Your Email Address" required>
                                            <div class="form-control-position">
                                                <i class="feather ft-mail"></i>
                                            </div>
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Email is required</div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="password" formControlName="password" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                                id="user-password" placeholder="Enter Password" required>
                                            <div class="form-control-position">
                                                <i class="la la-key"></i>
                                            </div>
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">Password is required</div>
                                                <div *ngIf="f.password.errors.minlength">Password must be at least 6
                                                    characters</div>
                                            </div>
                                        </fieldset>
                                        <div class="form-group row">
                                            <div class="col-md-6 col-12 text-center text-sm-left">
                                                <div class="custom-control custom-checkbox mb-1">
                                                    <input type="checkbox" class="custom-control-input"
                                                        name="remember-me" id="remember-me">
                                                    <label class="custom-control-label" for="remember-me">Remember
                                                        Me</label>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12 float-sm-left text-center text-sm-right"><a
                                                    href="recover-password.html" class="card-link">Forgot Password?</a>
                                            </div>
                                        </div>
                                        <button type="submit" class="btn btn-outline-info btn-block">
                                            <i class="fa fa-refresh fa-spin" *ngIf="submitted"></i>
                                            <i class="feather ft-user" *ngIf="!submitted"></i> Register
                                        </button>
                                    </form>
                                </div>
                                <div class="card-body">
                                    <a [routerLink]="['/login']" class="btn btn-outline-danger btn-block"><i
                                            class="feather ft-unlock"></i> Login</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div> -->
    </div>
</div>