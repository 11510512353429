import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { base_url } from '../../_configs/configurations';

@Injectable({
  providedIn: 'root'
})
export class MinhacontaService {

  constructor(private httpClient: HttpClient, ) { }

  getUserToken(){
    return sessionStorage.getItem("userToken");
  }

  registerMyAccount(data){
    return this.httpClient.post(`${base_url}minhaConta/cadastrarMinhaConta`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }

  registerUserAccount(data){
    return this.httpClient.post(`${base_url}authentication/register`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }

  cadastrarMinhaContaEmpresa(data){
    return this.httpClient.post(`${base_url}minhaContaEmpresa/cadastrarMinhaContaEmpresa`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }

  getParametros(userId){
    return this.httpClient.get(`${base_url}minhaContaParametro/listarMinhaContaParametros?usuarioId=${userId}&page=0&size=2000&sort=id,asc`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }

  editParametro(id, data){
    return this.httpClient.put(`${base_url}minhaContaParametro/editarMinhaContaParametro/${id}`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  editLogo(id, data){
    return this.httpClient.put(`${base_url}minhaConta/editarLogo/${id}`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  editarMinhaConta(id, data){
    return this.httpClient.put(`${base_url}minhaConta/editarMinhaConta/${id}`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  getLogo(userId){
    return this.httpClient.get(`${base_url}minhaConta/mostrarLogoConta/${userId}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }

  getUsuarios({page = 0, size = 10}){
    return this.httpClient.get(`${base_url}minhaContaUsuario/listarUsuarios?page=${page}&size=${size}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }

  listarDadosMinhaConta(){
    return this.httpClient.get(`${base_url}minhaConta/listarDadosMinhaConta`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }

  changePassword(data, senha){
    return this.httpClient.put(`${base_url}minhaContaUsuario/alterarSenha/${senha}`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  changeDados(data){
    return this.httpClient.put(`${base_url}minhaContaUsuario/alterarDados`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

}
