import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { base_url } from '../../_configs/configurations';


@Injectable({
  providedIn: 'root'
})
export class CobrancaService {

  constructor(private httpClient: HttpClient, ) { }

  getUserToken(){
    return sessionStorage.getItem("userToken");
  }

  criarCobranca(data){
    return this.httpClient.post(`${base_url}cobranca/cadastrarCobranca`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  alterarVencimentoCobranca(data){
    return this.httpClient.post(`${base_url}cobranca/editarVencimentoCobranca`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  getCobrancas(page, size, filtro){
    return this.httpClient.get(`${base_url}financeiroCobranca/listarFinanceiroCobrancaFormaPagamento?${filtro}page=${page}&size=${size}&sort=id,desc`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }

  getMensagens(cobrancaId){
    return this.httpClient.get(`${base_url}mensagem/listarMensagens?cobrancaId=${cobrancaId}&sort=idMensagem,desc`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }

  buscarSaldo(data){
    return this.httpClient.post(`${base_url}financeiroExtrato/saldo`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  inactiveCobranca(id){
    return this.httpClient.put(`${base_url}financeiroCobranca/inativarFinanceiroCobranca/${id}`, null,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  negativeCobranca(id){
    return this.httpClient.put(`${base_url}financeiroCobranca/editarFinanceiroCobrancaStatus/${id}/N`, null,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  protestCobranca(id){
    return this.httpClient.put(`${base_url}financeiroCobranca/editarFinanceiroCobrancaStatus/${id}/P`, null,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  sacarValores(data){
    return this.httpClient.post(`${base_url}financeiroExtratoSaque/cadastrarFinanceiroExtratoSaque`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  autorizarSaquePorSms(token, data){
    return this.httpClient.put(`${base_url}financeiroExtratoSaque/autorizarSaquePorSms/${token}`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  reenviarSmsAutorizacao(data){
    return this.httpClient.post(`${base_url}financeiroExtratoSaque/reenviarSmsAutorizacao`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  enviarEmailCobranca(data){
    return this.httpClient.post(`${base_url}financeiroCobranca/enviarEmailCobranca`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  alterarFormaPagamento(data){
    return this.httpClient.post(`${base_url}cobranca/editarFormaPagamento`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  criarLinkDePagamento(data){
    return this.httpClient.post(`${base_url}financeiroLinkCobranca/criarLink`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  getLinksPagamentos(){
    return this.httpClient.get(`${base_url}financeiroLinkCobranca/listarLinksPagamento`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }

  getLink(id){
    return this.httpClient.get(`${base_url}financeiroLinkCobranca/link/${id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  verificarPessoa(data){
    return this.httpClient.post(`${base_url}financeiroLinkCobranca/verificarPessoa`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    })
  }

  deleteLink(id){
    return this.httpClient.delete(`${base_url}financeiroLinkCobranca/delete/${id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }

  getCobrancasRecorrentes(){
    return this.httpClient.get(`${base_url}financeiroCobrancaRecorrente/listarCobrancasRecorrentes?size=1000000&sort=dataInico,asc`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }

  criarCobrancaRecorrente(data){
    return this.httpClient.post(`${base_url}financeiroCobrancaRecorrente/criarCobrancaRecorrente`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  deleteCobrancaRecorrente(id){
    return this.httpClient.delete(`${base_url}financeiroCobrancaRecorrente/${id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }

  editarCobrancaRecorrete(id, data){
    return this.httpClient.put(`${base_url}financeiroCobrancaRecorrente/${id}`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

  getListCobrancasRecorrentes(recorrenciaId, paginationLimit, paginationCurrentPage){
    return this.httpClient.get(`${base_url}financeiroCobranca/listarFinanceiroCobrancaFormaPagamento?recorrenciaId=${recorrenciaId}&page=${paginationCurrentPage}&size=${paginationLimit}&sort=id,desc`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }

  getDashboard(ano){
    return this.httpClient.get(`${base_url}financeiroCobranca/getDashboardCobranca/${ano}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }

  getTotalRecebimentos(ano){
    return this.httpClient.get(`${base_url}financeiroCobranca/getTotalRecebimentos/${ano}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    });
  }

  gerarCobrancaRecorrente(data){
    return this.httpClient.post(`${base_url}financeiroCobrancaRecorrente/gerarCobrancaRecorrente`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getUserToken()}`
      })
    })
  }

}
